﻿// Start the main app logic.
define([
	'pubsub',
	'loginControl', 'toolbarControl', 'languageControl', 'mixpanelControl'],

	function (pubsub) {

		'use strict';

		// Landed event of mixpanel for login page is taracked  in RESAASMP.js


		//init login control
		pubsub.publish('uiAction.LoginControlInit');
	}
);