﻿define(['pubsub', 'ajaxUtility'],

	function (pubsub, ajaxUtility) {
		'use strict';

		function login(msg, loginData) {
			var request;

			request = {
				'method': 'POST',
				'data': loginData,
				'url': '/Services/AuthService.svc/json/SignIn',
				'contentType': 'application/json; charset=UTF-8',
				'dataType': 'json'
			};

			ajaxUtility.publish('dataLogin', request);
		}

		function associate(msg, loginData) {
			var request;

			request = {
				'method': 'POST',
				'data': loginData,
				'url': '/Services/AuthService.svc/json/LinkSocialAccount',
				'contentType': 'application/json; charset=UTF-8',
				'dataType': 'json'
			};

			ajaxUtility.publish('dataAssociateLogin', request);
		}

		pubsub.subscribe('uiHasLogin', login);
		pubsub.subscribe('uiHasAssociateLogin', associate);
	}
);